import { Player, Team } from './state'

export const teams: Team[] = [
  { teamId: 76701, name: 'TCB III' },
  { teamId: 76702, name: 'Strategic Luck' },
]

// prettier-ignore
export const players: Player[] = [
  { rating: 52, playerId: 21263, teamId: 76701, name: 'Anna "BridgQueen" Reiter' },
  { rating: 65, playerId: 11957, teamId: 76701, name: 'Karl "Kaptin" Bohnsack' },
  { rating: 70, playerId: 21214, teamId: 76701, name: 'Margaret Wind' },
  { rating: 100, playerId: 23004, teamId: 76701, name: 'Nate Badger' },
  { rating: 57, playerId: 25742, teamId: 76701, name: 'Edwin Paul' },
  { rating: 41, playerId: 25843, teamId: 76701, name: 'Doug Little' },
  { rating: 50, playerId: 37566, teamId: 76701, name: 'Cindy "Digger" Gilbert' },
  { rating: 47, playerId: 37772, teamId: 76701, name: 'Tracy Badger' },

  { rating: 68, playerId: 22713, teamId: 76702, name: 'Bob "S.B.D." Cox' },
  { rating: 84, playerId: 9621, teamId: 76702, name: 'Brian Dullea' },
  { rating: 77, playerId: 21407, teamId: 76702, name: 'Ryan Blilie' },
  { rating: 73, playerId: 22579, teamId: 76702, name: 'Kyle Runtsch' },
  { rating: 40, playerId: 26065, teamId: 76702, name: 'Kimberly Larson' },
  { rating: 40, playerId: 35392, teamId: 76702, name: 'Amanda"Tigger" Cox' },
  { rating: 73, playerId: 39164, teamId: 76702, name: 'Peter DeVries' },
]
