import { useParams } from 'react-router-dom'

export function useSettings() {
  const params = useParams()

  const teamId = params.teamId || '76705'
  const divisionId = params.divisionId || teamId.substring(0, 3)

  return {
    teamId,
    divisionId,
  }
}
