import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Typography,
} from '@mui/material'
import { format, parseISO } from 'date-fns'
import { Link as RouterLink } from 'react-router-dom'
import { Team } from '../../shared'
import { useSettings } from '../useSettings'
import { useDivision } from './DivisionProvider'
import { RatingsTable } from './RatingsTable'

export function UpcomingMatchTable() {
  const { division } = useDivision()
  const { teamId } = useSettings()

  if (!division) return null

  const week = division.schedule.find(
    (w) =>
      w.week >= division.week &&
      w.matches.some(
        (m) =>
          (m.home === teamId && division.teams.find((t) => t.id === m.away)) ||
          (m.away === teamId && division.teams.find((t) => t.id === m.home)),
      ),
  )

  if (!week) return null

  const match = week.matches.find((m) => m.home === teamId || m.away === teamId)

  if (!match) return null

  const home = division.teams.find((team) => team.id === match.home) as Team
  const away = division.teams.find((team) => team.id === match.away) as Team
  const team = home.id === teamId ? home : away
  const opponent = home.id === teamId ? away : home

  const header = (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item sm={12} md="auto">
        Next Match
      </Grid>
      <Grid item sm={12} md="auto" sx={{ typography: 'subtitle1' }}>
        {format(parseISO(week.date), 'MMMM d, yyyy')}
      </Grid>
      <Grid item sm={12} md="auto" sx={{ typography: 'subtitle1' }}>
        {home.location.name} | {home.location.city}, {home.location.state}
      </Grid>
    </Grid>
  )

  return (
    <Card elevation={0}>
      <CardHeader title={header}></CardHeader>
      <CardContent>
        <Grid container justifyContent="space-evenly">
          <Grid item sm="auto">
            <TeamCard team={team} />
          </Grid>
          <Grid item sm="auto">
            <TeamCard team={opponent} linkTeam />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

function TeamCard({ team, linkTeam }: { team: Team; linkTeam?: boolean }) {
  return (
    <Card elevation={0}>
      <CardHeader
        title={
          <span>
            <Typography variant="caption">#{team.rank} </Typography>
            {linkTeam ? (
              <Link
                component={RouterLink}
                to={`../team/${team.id}`}
                color="ButtonText"
              >
                {team.name}
              </Link>
            ) : (
              team.name
            )}
          </span>
        }
        sx={{ textAlign: 'center' }}
      />
      <RatingsTable teamId={team.id} />
    </Card>
  )
}
