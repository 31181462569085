import {
  Card,
  CardHeader,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'
import { format, parseISO } from 'date-fns'
import { Link as RouterLink } from 'react-router-dom'
import { Match, Team } from '../../shared'
import { useSettings } from '../useSettings'
import { useDivision } from './DivisionProvider'

export function MatchesTable() {
  const { division } = useDivision()

  if (!division) return null

  const week = division.schedule.find((w) => w.week === division.week)

  if (!week) return null

  const header = (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography typography="h5">Week {week.week} Matches</Typography>
      <Typography variant="subtitle1">
        {format(parseISO(week.date), 'MMMM d, yyyy')}
      </Typography>
    </Stack>
  )

  return (
    <Card elevation={0}>
      <CardHeader title={header}></CardHeader>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {week.matches.map((match) => (
              <MatchRow key={match.home} match={match} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

function MatchRow({ match }: { match: Match }) {
  const { division } = useDivision()
  const { teamId } = useSettings()

  const home = division?.teams.find((team) => team.id === match.home)
  const away = division?.teams.find((team) => team.id === match.away)

  if (!home || !away) return null

  return (
    <TableRow selected={match.home === teamId || match.away === teamId}>
      <TableCell align="right">
        <TeamLink team={home} home />
      </TableCell>
      <TableCell>vs</TableCell>
      <TableCell>
        <TeamLink team={away} />
      </TableCell>
    </TableRow>
  )
}

function TeamLink({ team, home }: { team: Team; home?: boolean }) {
  return (
    <>
      <Typography
        variant="caption"
        marginLeft={home ? 1 : 0}
        marginRight={home ? 0 : 1}
        sx={{ float: home ? 'right' : 'left' }}
      >
        #{team.rank}
      </Typography>
      <Link component={RouterLink} to={`../team/${team.id}`}>
        {team.name}
      </Link>
    </>
  )
}
