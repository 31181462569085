import { AppBar, Box, Tab, Tabs, Typography } from '@mui/material'
import { useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { Team } from '../../shared'
import { useSettings } from '../useSettings'
import { useDivision } from './DivisionProvider'
import { RatingsTable } from './RatingsTable'

export function MobileMatch() {
  const { division } = useDivision()
  const { teamId } = useSettings()

  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index: number) => {
    setValue(index)
  }

  if (!division) return null

  const week = division.schedule.find(
    (w) =>
      w.week >= division.week &&
      w.matches.some((m) => m.home === teamId || m.away === teamId),
  )

  if (!week) return null

  const match = week.matches.find((m) => m.home === teamId || m.away === teamId)

  if (!match) return null

  const home = division.teams.find((team) => team.id === match.home) as Team
  const away = division.teams.find((team) => team.id === match.away) as Team
  const team = home.id === teamId ? home : away
  const opponent = home.id === teamId ? away : home

  return (
    <>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
        >
          <Tab label={<TeamTabLabel team={team} />} />
          <Tab label={<TeamTabLabel team={opponent} />} />
        </Tabs>
      </AppBar>
      <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
        <TabPanel value={value} index={0}>
          <TeamCard team={team} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TeamCard team={opponent} />
        </TabPanel>
      </SwipeableViews>
    </>
  )
}

function TeamTabLabel({ team }: { team: Team }) {
  return (
    <span>
      <Typography variant="caption">#{team.rank} </Typography>
      {team.name}
    </span>
  )
}

function TeamCard({ team }: { team: Team }) {
  return <RatingsTable teamId={team.id} />
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel({ children, value, index }: TabPanelProps) {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  )
}
