import {
  Card,
  CardHeader,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { max, orderBy } from 'lodash'
import { Link as RouterLink } from 'react-router-dom'
import { useSettings } from '../useSettings'
import { useDivision } from './DivisionProvider'

export function StandingsTable({ extended }: { extended?: boolean }) {
  const { division } = useDivision()
  const { teamId, divisionId } = useSettings()

  const teams = orderBy(division?.teams ?? [], (team) => team.rank)

  const maxPoints = max(teams.map((team) => team.totalPoints)) ?? 0

  return (
    <Card elevation={0}>
      <CardHeader
        title={
          <Link component={RouterLink} to={`../division/${divisionId}`}>
            Standings
          </Link>
        }
      />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ width: 0 }}>
                Rank
              </TableCell>
              <TableCell>Team</TableCell>
              {extended && (
                <>
                  <TableCell
                    align="center"
                    sx={{ width: 0, whiteSpace: 'nowrap' }}
                  >
                    Points Last Week
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ width: 0, whiteSpace: 'nowrap' }}
                  >
                    Sets Played
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ width: 0, whiteSpace: 'nowrap' }}
                  >
                    Total Points
                  </TableCell>
                </>
              )}
              <TableCell align="center" sx={{ width: 0, whiteSpace: 'nowrap' }}>
                Points / Match
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teams.map((team) => (
              <TableRow key={team.id} selected={team.id === teamId}>
                <TableCell align="center">{team.rank}</TableCell>
                <TableCell>
                  <Link component={RouterLink} to={`../team/${team.id}`}>
                    {team.name}
                  </Link>
                </TableCell>
                {extended && (
                  <>
                    <TableCell align="center">{team.pointsLastWeek}</TableCell>
                    <TableCell align="center">{team.setsPlayed}</TableCell>
                    <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                      {team.totalPoints}
                      {team.totalPoints < maxPoints
                        ? ` (${team.totalPoints - maxPoints})`
                        : ''}
                    </TableCell>
                  </>
                )}
                <TableCell align="center">{team.pointsPerSet * 5}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}
