import { Card, CardHeader, Grid, Stack } from '@mui/material'
import { RatingsTable } from './RatingsTable'
import { ScheduleTable } from './ScheduleTable'
import { StandingsTable } from './StandingsTable'
import { UpcomingMatchTable } from './UpcomingMatchTable'

export function TeamPage() {
  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <Stack spacing={2}>
          <UpcomingMatchTable />
          <ScheduleTable />
        </Stack>
      </Grid>
      <Grid item xs="auto">
        <Stack spacing={2}>
          <StandingsTable />
          <Card elevation={0}>
            <CardHeader title="Roster" />
            <RatingsTable />
          </Card>
        </Stack>
      </Grid>
    </Grid>
  )
}
