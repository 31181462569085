import { CircularProgress, Stack } from '@mui/material'
import {
  documentId,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { createContext, useContext, useEffect, useState } from 'react'
import { Division } from '../../shared'
import { collection } from '../firebase'
import { useSettings } from '../useSettings'

type DivisionContextValue = ReturnType<typeof useDivisionContextValue>

const defaultContextValue = {
  division: undefined,
}

const DivisionContext = createContext<DivisionContextValue>(defaultContextValue)

export function DivisionProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const value = useDivisionContextValue()

  if (!value.division) {
    return (
      <Stack justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress size={64} />
      </Stack>
    )
  }

  return (
    <DivisionContext.Provider value={value}>
      {children}
    </DivisionContext.Provider>
  )
}

export function useDivision() {
  return useContext(DivisionContext)
}

function useDivisionContextValue() {
  const [division, setDivision] = useState<Division>()
  const { divisionId } = useSettings()

  useEffect(() => {
    async function fetchData() {
      const colRef = collection<Division>('divisions')
      const q = query(
        colRef,
        where('id', '==', divisionId),
        orderBy(documentId(), 'desc'),
        limit(1),
      )

      const querySnapshot = await getDocs(q)
      const divisions = querySnapshot.docs.map((docRef) => docRef.data())

      if (divisions.length) {
        setDivision(divisions[0])
      }
    }
    fetchData()
  }, [divisionId])

  return {
    division,
  }
}
