import {
  Card,
  CardHeader,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { orderBy } from 'lodash'
import { Link as RouterLink } from 'react-router-dom'
import { useSettings } from '../useSettings'
import { useDivision } from './DivisionProvider'

export function MvpTable() {
  const { division } = useDivision()
  const { teamId } = useSettings()

  const players = orderBy(
    division?.teams.flatMap((team) =>
      team.players
        .filter((player) => player.mvp)
        .map((player) => ({ player, team })),
    ),
    ['player.mvp.rank', 'player.rating', 'player.name'],
    ['asc', 'desc', 'asc'],
  )

  return (
    <Card elevation={0}>
      <CardHeader title="MVP Standings" />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ width: 0 }}>
                Rank
              </TableCell>
              <TableCell>Player</TableCell>
              <TableCell>Team</TableCell>
              <TableCell align="center" sx={{ width: 0 }}>
                Rating
              </TableCell>
              <TableCell align="center" sx={{ width: 0 }}>
                Record
              </TableCell>
              <TableCell align="center" sx={{ width: 0 }}>
                MVP Points
              </TableCell>
            </TableRow>
          </TableHead>
          {!!players && (
            <TableBody>
              {players.map(({ player, team }) => (
                <TableRow key={player.id} selected={team.id === teamId}>
                  <TableCell align="center">{player.mvp?.rank}</TableCell>
                  <TableCell>{player.name}</TableCell>
                  <TableCell>
                    <Link component={RouterLink} to={`../team/${team.id}`}>
                      {team.name}
                    </Link>
                  </TableCell>
                  <TableCell align="center">{player.rating}</TableCell>
                  <TableCell align="center">
                    {player.wins}-{player.played - player.wins}
                  </TableCell>
                  <TableCell align="center">
                    {(player.mvp?.points ?? 0) +
                      200 * (player.wins - (player.played - player.wins))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Card>
  )
}
