import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { orderBy } from 'lodash'
import { useSettings } from '../useSettings'
import { useDivision } from './DivisionProvider'

export interface RatingsTableProps {
  teamId?: string
}

export function RatingsTable({ teamId }: RatingsTableProps) {
  const settings = useSettings()
  const { division } = useDivision()

  teamId ||= settings.teamId

  const players = orderBy(
    division?.teams.find((team) => team.id === teamId)?.players ?? [],
    ['rating', 'name'],
    ['desc', 'asc'],
  )

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{ width: 0 }}>
              Rating
            </TableCell>
            <TableCell>Player</TableCell>
            <TableCell align="center" sx={{ width: 0 }}>
              Record
            </TableCell>
            <TableCell align="center" sx={{ width: 0 }}>
              MVP
            </TableCell>
          </TableRow>
        </TableHead>
        {!!players && (
          <TableBody>
            {players.map((player) => (
              <TableRow key={player.id}>
                <TableCell align="center">{player.rating}</TableCell>
                <TableCell>{player.name}</TableCell>
                <TableCell align="center">
                  {player.wins}-{player.played - player.wins}
                </TableCell>
                <TableCell align="center">
                  {player.mvp && `#${player.mvp?.rank}`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  )
}
