import {
  configureStore,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { keyBy, orderBy, range, sumBy } from 'lodash'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { players as allPlayers, teams as allTeams } from './data'
// import { getFinalSetPoints } from './helpers'

export interface PoolState {
  teams: Record<number, TeamState>
  players: Record<number, PlayerState>
  match: MatchState
}

const initialState: PoolState = {
  teams: keyBy(allTeams, (team) => team.teamId),
  players: keyBy(allPlayers, (player) => player.playerId),
  match: createMatch(new Date().toISOString(), 76701, 76702),
}

export const poolSlice = createSlice({
  name: 'pool',
  initialState,
  reducers: {
    updateSetPlayer: (
      state,
      {
        payload: { setIndex, playerId, position },
      }: PayloadAction<{
        setIndex: number
        playerId: number
        position: number
      }>,
    ) => {
      state.match.sets[setIndex].playerIds[position] = playerId
    },
    swapPlayers: (
      state,
      { payload: { setIndex } }: PayloadAction<{ setIndex: number }>,
    ) => {
      const set = state.match.sets[setIndex]
      set.teamOrder = swap(set.teamOrder)
      set.playerIds = swap(set.playerIds)
      set.games.forEach((game) => {
        game.points = swap(game.points)
      })
    },
    updateGamePoints: (
      state,
      {
        payload: { setIndex, gameIndex, teamIndex, points },
      }: PayloadAction<{
        setIndex: number
        gameIndex: number
        teamIndex: number
        points: number
      }>,
    ) => {
      const games = state.match.sets[setIndex].games
      const game = games[gameIndex]
      const match = selectMatch({ pool: state })
      game.points[teamIndex] = points
      if (games.every(isGameValid) && !match.sets[setIndex].isComplete) {
        games.push(createGame())
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateSetPlayer, swapPlayers, updateGamePoints } =
  poolSlice.actions

// Selectors
const selectPool = (state: RootState) => state.pool
const selectTeamsById = (state: RootState) => selectPool(state).teams
const selectPlayersById = (state: RootState) => selectPool(state).players

export const selectMatch = createSelector(
  (state: RootState) => selectPool(state).match,
  selectTeamsById,
  selectPlayersById,
  (matchState, teamsById, playersById) => {
    const players: PlayerState[] = Object.values(playersById)

    const teams = matchState.teamIds.map((teamId) => ({
      teamId,
      name: teamsById[teamId].name,
      players: orderBy(
        players.filter((player) => player.teamId === teamId),
        [(player) => player.rating, (player) => player.name],
        ['desc', 'asc'],
      ),
    }))

    const match: Match = {
      date: matchState.date,
      teams,
      sets: matchState.sets.map((set) => {
        const games = set.games.map((game) => ({
          ...game,
          isValid: isGameValid(game),
          isInvalid: isGameInvalid(game),
        }))

        const slots = set.teamOrder.map((teamId, ti) => {
          const playerId = set.playerIds[ti]
          const player = playerId ? playersById[playerId] : undefined
          const points = getSetPoints(set.games.map((g) => g.points[ti]))
          const pointsNeeded =
            player != null && points != null
              ? Math.max(0, player.rating - points)
              : undefined

          return {
            team: teams.find((t) => t.teamId === teamId) as Team,
            player,
            points,
            pointsNeeded,
          }
        })

        const isComplete = slots.some(
          ({ points, player }) => points && player && points >= player.rating,
        )

        return {
          games,
          slots,
          isComplete,
        }
      }),
    }

    return match
  },
)

export const store = configureStore({
  reducer: {
    pool: poolSlice.reducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

export interface TeamState {
  teamId: number
  name: string
}

export interface PlayerState {
  playerId: number
  teamId: number
  name: string
  rating: number
}

export interface MatchState {
  date: string
  teamIds: number[]
  sets: SetState[]
}

export interface SetState {
  teamOrder: number[]
  playerIds: (number | undefined)[]
  games: GameState[]
}

export interface GameState {
  points: number[]
  innings: number
}

export interface Match {
  date: string
  teams: Team[]
  sets: Set[]
}

export interface Team {
  teamId: number
  name: string
  players: PlayerState[]
}

export interface Set {
  games: Game[]

  isComplete: boolean

  slots: Array<{
    team: Team
    player?: PlayerState
    safeties?: number
    points?: number
    pointsNeeded?: number
  }>
}

export interface Game {
  points: number[]
  innings: number
  isValid: boolean
  isInvalid: boolean
}

export function createMatch(
  date: string,
  team1Id: number,
  team2Id: number,
): MatchState {
  const teamIds = [team1Id, team2Id]

  return {
    date,
    teamIds,
    sets: range(5).map(() => createSet(teamIds)),
  }
}

function createSet(teamOrder: number[]): SetState {
  return {
    teamOrder,
    playerIds: [undefined, undefined],
    games: [createGame()],
  }
}

function createGame(): GameState {
  return { points: [NaN, NaN], innings: NaN }
}

function swap<T>(arr: T[]): T[] {
  const [a, b] = arr
  return [b, a]
}

function isGameValid(game: GameState): boolean {
  const hasOneWinner = game.points.filter((value) => value >= 8).length === 1
  const hasOneLoser = game.points.filter((value) => value < 8).length === 1
  return hasOneWinner && hasOneLoser
}

function isGameInvalid(game: GameState): boolean {
  return game.points.some(isNaN) ? false : !isGameValid(game)
}

function getSetPoints(points: number[]): number | undefined {
  if (!points.length || points.every((p) => isNaN(p))) {
    return undefined
  }

  return sumBy(points, (p) => (isNaN(p) ? 0 : p))
}
