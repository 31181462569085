import { Grid, Stack } from '@mui/material'
import { MatchesTable } from './MatchesTable'
import { MvpTable } from './MvpTable'
import { StandingsTable } from './StandingsTable'

export function DivisionPage() {
  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <Stack spacing={2}>
          <StandingsTable extended />
          <MatchesTable />
        </Stack>
      </Grid>
      <Grid item xs>
        <Stack spacing={2}>
          <MvpTable />
        </Stack>
      </Grid>
    </Grid>
  )
}
