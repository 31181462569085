import {
  Card,
  CardHeader,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { format, parseISO } from 'date-fns'
import { Link as RouterLink } from 'react-router-dom'
import { useSettings } from '../useSettings'
import { useDivision } from './DivisionProvider'

export function ScheduleTable() {
  const { teamId } = useSettings()
  const { division } = useDivision()

  if (!division) return null

  const schedule = division.schedule.map((week) => {
    const match = week.matches.find(
      (match) => match.home === teamId || match.away === teamId,
    )

    if (!match) {
      return {
        week: week.week,
        date: week.date,
        message: 'PLAYOFFS',
      }
    }

    const getTeam = (teamId: string) =>
      division.teams.find((team) => team.id === teamId)

    const home = getTeam(match.home)
    const away = getTeam(match.away)

    const opponent = match.home === teamId ? away : home

    return {
      week: week.week,
      date: week.date,
      opponent,
      location: home?.location,
      message: 'BYE',
    }
  })

  return (
    <Card elevation={0}>
      <CardHeader title="Schedule" />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ width: 0 }}>
                Week
              </TableCell>
              <TableCell align="center" sx={{ width: 0 }}>
                Date
              </TableCell>
              <TableCell>Opponent</TableCell>
              <TableCell>Venue</TableCell>
              <TableCell>City</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schedule.map((week) => (
              <TableRow key={week.week} selected={week.week === division.week}>
                <TableCell align="center">{week.week}</TableCell>
                <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                  {format(parseISO(week.date), 'MMM d')}
                </TableCell>
                <TableCell>
                  {week.opponent ? (
                    <Link
                      component={RouterLink}
                      to={`../team/${week.opponent.id}`}
                    >
                      {week.opponent.name}
                    </Link>
                  ) : (
                    week.message
                  )}
                </TableCell>
                <TableCell>{week.location?.name}</TableCell>
                <TableCell>
                  {!!week.location &&
                    `${week.location.city}, ${week.location.state}`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}
