import { Route, Routes } from 'react-router-dom'
import { DivisionPage } from './DivisionPage'
import { DivisionProvider } from './DivisionProvider'
import { MobileMatch } from './MobileMatch'
import { TeamPage } from './TeamPage'

export function DivisionRoutes() {
  return (
    <DivisionProvider>
      <Routes>
        <Route path="division/:divisionId" element={<DivisionPage />} />
        <Route path="team/:teamId" element={<TeamPage />} />
        <Route path="upcoming" element={<MobileMatch />} />
        <Route index element={<TeamPage />} />
      </Routes>
    </DivisionProvider>
  )
}
