import { initializeApp } from 'firebase/app'
import {
  collection as fsCollection,
  CollectionReference,
  getFirestore,
} from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyBnweOyMqc8t6H2kTNGDdo0DLhZog9MMn0',
  authDomain: 'pool-6bf4a.firebaseapp.com',
  projectId: 'pool-6bf4a',
  storageBucket: 'pool-6bf4a.appspot.com',
  messagingSenderId: '723197155343',
  appId: '1:723197155343:web:236d01b5cbb3b95907eb7a',
}

const app = initializeApp(firebaseConfig)

export const db = getFirestore(app)

export function collection<T>(path: string) {
  return fsCollection(db, path) as CollectionReference<T>
}
