import { createTheme } from '@mui/material'

const baseTheme = createTheme()

export const theme = createTheme({
  palette: {
    background: {
      default: baseTheme.palette.grey[200],
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          padding: 16,
        },
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          textAlign: 'center',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
  },
})
